// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery");
require("jquery-ui");
require("jquery-ujs");

// kladr-api
require('packs/jquery/jquery.fias.min.js');
$.fias.token = '570b56700a69de450d8b456e';
$.fias.url = 'https://kladr-api.com/api.php';

require('packs/jquery/social-share-button');
require("packs/jquery/jewelryModals");
require("packs/jquery/script");
require('packs/jquery/core_functions');
require('packs/jquery/loader');
require('packs/jquery/multipart-form');
require('packs/jquery/js_onload');

// qr
require("jquery.qrcode");
window.jsQR = require('jsqr');
window.raf = require('raf');
import debounce from 'lodash.debounce';
window.debounce = debounce;
require('packs/jquery/js_qr');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
