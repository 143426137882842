!function (t, e) {
  function n(t) {
    var e = {}, n = {type: "contentType", name: "query", withParents: "withParent"};
    t.parentType && t.parentId && (e[t.parentType + "Id"] = t.parentId);
    for (var a in t) r(t, a) && t[a] && (e[r(n, a) ? n[a] : a] = t[a]);
    return e
  }

  function r(t, e) {return t.hasOwnProperty(e)}

  function a(t) {
    var n = e.console;
    n && n.error && n.error(t)
  }

  t.fias = {}, function () {
    var e = "https:";
    t.fias.url = e + "//kladr-api.ru/api.php", t.fias.timeout = 3e3
  }();
  var i = [], o = function () {
    for (; i.length > 5;) {
      var t = i.shift();
      t.abort()
    }
  };
  t.fias.type = {
    region: "region",
    district: "district",
    city: "city",
    street: "street",
    building: "building"
  }, t.fias.typeCode = {city: 1, settlement: 2, village: 4}, t.fias.validate = function (e) {
    var n = t.fias.type;
    switch (e.type) {
      case n.region:
      case n.district:
      case n.city:
        if (e.parentType && !e.parentId) return a("parentId undefined"), !1;
        break;
      case n.street:
        if (e.parentType != n.city && e.parentType != n.street) return a('parentType must equal "city" or "street"'), !1;
        if (!e.parentId) return a("parentId undefined"), !1;
        break;
      case n.building:
        if (!e.zip) {
          if (!~t.inArray(e.parentType, [n.street, n.city])) return a('parentType must equal "street" or "city"'), !1;
          if (!e.parentId) return a("parentId undefined"), !1
        }
        break;
      default:
        if (!e.oneString) return a("type incorrect"), !1
    }
    return e.oneString && e.parentType && !e.parentId ? (a("parentId undefined"), !1) : e.typeCode && e.type != n.city ? (a('type must equal "city"'), !1) : !(e.limit < 1) || (a("limit must greater than 0"), !1)
  }, t.fias.api = function (e, r) {
    if (!r) return void a("Callback undefined");
    if (!t.fias.validate(e)) return void r([]);
    var l = setTimeout(function () {r([]), l = null}, t.fias.timeout);
    !e.token && t.fias.token && (e.token = t.fias.token), o();
    var u = t.ajax({url: t.fias.url + "?callback=?", type: "get", data: n(e), dataType: "jsonp"});
    i.push(u), u.done(function (t) {l && (r(t.result || []), clearTimeout(l))})
  }, t.fias.check = function (e, n) {return n ? (e.withParents = !1, e.limit = 1, void t.fias.api(e, function (t) {n(t && t.length ? t[0] : !1)})) : void a("Callback undefined")}
}(jQuery, window), function (t, e, n, r) {
  function a(r, a) {
    function i(t, e) {return t.isGet ? c.get(t.str[0]) : (c.set(t), void e())}

    var c = function () {
      var e = "kladr-data", n = r.data(e);
      return n || (n = t.extend({}, u, s), r.data(e, n)), {
        set: function (t) {
          if (t.obj) for (var a in t.obj) l(t.obj, a) && l(u, a) && (n[a] = t.obj[a]); else t.str && !t.isGet && l(u, t.str[0]) && (n[t.str[0]] = t.str[1]);
          r.data(e, n)
        },
        get: function (t) {if (l(u, t) || l(s, t)) return n[t]},
        _set: function (t, a) {n[t] = a, r.data(e, n)},
        _get: function (t) {if (l(n, t)) return n[t]}
      }
    }();
    return i(a, function () {
      function a(a) {
        var i = t(n.getElementById("kladr_autocomplete"));
        i.length || (i = t('<div id="kladr_autocomplete"></div>').appendTo(n.body));
        var l = B("guid");
        l ? (V = i.find(".autocomplete" + l), A = i.find(".spinner" + l), t(e).off(z), r.off(z), V.off(z)) : (l = o(), P("guid", l), r.attr("autocomplete", "off"), V = t('<ul class="autocomplete' + l + ' autocomplete" style="display: none;"></ul>').appendTo(i), A = t('<div class="spinner' + l + ' spinner" style="display: none;"></div>').appendTo(i), k(), d(), b()), a()
      }

      function i(e, n) {
        var r, a, i, o;
        V.empty();
        for (var l = 0; l < e.length; l++) {
          r = e[l], a = B("valueFormat")(r, n), i = B("labelFormat")(r, n);
          var u = a.replace(/(["])/g, "\\$1"), s = t("<div>" + i + "</div>").text().replace(/(["])/g, "\\$1");
          o = t('<a data-val="' + u + '">' + i + "</a>"), o.data("kladr-object", r), t('<li title="' + s + '"></li>').append(o).appendTo(V)
        }
      }

      function s() {
        var e, n, r;
        V.empty(), e = "", n = u.noResultText, null != n && "" != n && (r = t('<a data-val="' + e + '">' + n + "</a>"), r.data("kladr-object", {}), t("<li></li>").append(r).appendTo(V))
      }

      function d() {
        var t = r.offset(), e = r.outerWidth(), n = r.outerHeight();
        if (t && (d.top != t.top || d.left != t.left || d.width != e || d.height != n)) {
          d.top = t.top, d.left = t.left, d.width = e, d.height = n, V.css({
            top: t.top - 1 + n + "px",
            left: t.left
          });
          var a = V.outerWidth() - V.width();
          V.width(e - a);
          var i = A.width(), o = A.height();
          A.css({top: t.top + (n - o) / 2 - 1, left: t.left + e - i - 2})
        }
      }

      function p(e) {
        if (!(e.which > 8 && e.which < 46)) {
          if (r.data(F, !1), !w("open_before")) return void v();
          _(null);
          var n = r.val();
          if (!t.trim(n)) return x(!1), void v();
          var a = j(n);
          if (!w("send_before", a)) return void v();
          T(), w("send"), B("source")(a, function (e) {return w("receive", e), r.is(":focus") ? t.trim(r.val()) && e.length ? (G = !0, i(e, a), d(), I(), V.slideDown(50), void w("open")) : (I(), _(null), s(), d(), V.slideDown(50), w("open"), void (G = !1)) : (I(), void v())})
        }
      }

      function v() {w("close_before") && (V.empty().hide(), w("close"))}

      function y(t) {
        var e = V.find("li.active");
        switch (t.which) {
          case f.up:
            e.length ? (e.removeClass("active"), e.prev().length && (e = e.prev())) : e = V.find("li").last(), function () {
              var t = V.scrollTop(), n = V.offset(), r = e.outerHeight(), a = e.offset();
              a.top - n.top < 0 && V.scrollTop(t - r)
            }(), e.addClass("active"), g();
            break;
          case f.down:
            e.length ? (e.removeClass("active"), e.next().length && (e = e.next())) : e = V.find("li").first(), e.length && !function () {
              var t = V.scrollTop(), n = V.height(), r = V.offset(), a = e.outerHeight(), i = e.offset();
              i.top - r.top + a > n && V.scrollTop(t + a)
            }(), e.addClass("active"), g();
            break;
          case f.enter:
            v()
        }
      }

      function h(e) {
        var n = t(e);
        n.is("a") && (n = n.parents("li")), n.addClass("active"), g(), v()
      }

      function g() {
        if (w("select_before")) {
          var t = V.find(".active a");
          t.length && (r.val(t.attr("data-val")).data(F, !0), x(!1), _(t.data("kladr-object")), w("select", B("current")))
        }
      }

      function m() {
        function e(t, e) {x(e), _(t)}

        if (B("verify") && w("check_before")) {
          var n = t.trim(r.val());
          if (!n) return void e(null, !1);
          if (B("current")) return void x(!1);
          var a = j(n);
          if (a.withParents = !1, a.limit = 10, !w("send_before", a)) return e(null, !1), void w("check", null);
          T(), w("send"), B("source")(a, function (n) {
            function i(t, n) {I(), e(t, n)}

            if (w("receive"), !t.trim(r.val())) return void i(null, !1);
            for (var o = a.name.toLowerCase(), l = null, u = null, s = 0; s < n.length; s++) if (l = n[s].name.toLowerCase(), o == l) {
              u = n[s];
              break
            }
            u && r.val(B("valueFormat")(u, a)), i(u, !u), w("check", u)
          })
        }
      }

      function k() {
        function e() {r.attr(i, !0)}

        function n(t, e, n) {t ? r.val(B("valueFormat")(t, e)) : x(!0), _(t, n), r.removeAttr(i)}

        var a = {
          setValue: function (e, n) {return "object" === t.type(e) ? a.setValueByObject(e, n) : "number" === t.type(e) ? a.setValueById(e, n) : "string" === t.type(e) ? a.setValueByName(e, n) : e ? a : a.clear(n)},
          setValueByName: function (r, i) {
            if (r = t.trim(r + "")) {
              var o = j("");
              if (o.name = S(r), o.withParents = !1, o.limit = 10, !w("send_before", o)) return n(null, o, i), a;
              e(), w("send"), B("source")(o, function (t) {
                w("receive");
                for (var e = o.name.toLowerCase(), r = null, a = null, l = 0; l < t.length; l++) if (r = t[l].name.toLowerCase(), e == r) {
                  a = t[l];
                  break
                }
                n(a, o, i)
              })
            }
            return a
          },
          setValueById: function (r, i) {
            var o = j("");
            return o.parentType = o.type, o.parentId = r, o.limit = 1, e(), t.fias.api(o, function (t) {t.length ? n(t[0], o, i) : n(null, o, i)}), a
          },
          setValueByObject: function (t, e) {return n(t, j(""), e), a},
          clear: function (t) {return n(null, null, t), a}
        }, i = "data-kladr-autofill-lock";
        P("controller", a)
      }

      function b() {
        function e() {
          var e = r.val();
          if (e) {
            var n, a = j(e), i = a.type, o = a.parentType, l = t.fias.type, u = !0, s = B("controller").setValueByName;
            return i == l.street && o != l.city && (u = !1), i != l.building || ~t.inArray(o, [l.street, l.city]) || (u = !1), n = r.attr("data-kladr-autofill-lock"), n && B("current") && u && s(e), !!B("current")
          }
          return !1
        }

        var n = 0;
        !function a() {++n > 5 || e() || setTimeout(a, 100)}()
      }

      function w(e, n, a) {
        if (!e) return !0;
        var i = e.replace(/_([a-z])/gi, function (t, e) {return e.toUpperCase()});
        return r.trigger("kladr_" + e, n), "function" !== t.type(B(i)) || B(i).call(r.get(0), n, a) !== !1
      }

      function T() {B("spinner") && B("showSpinner")(A)}

      function I() {B("spinner") && B("hideSpinner")(A)}

      function j(t) {
        var e, n = {},
          r = ["token", "type", "typeCode", "parentType", "parentId", "oneString", "withParents", "limit", "strict"];
        for (e = 0; e < r.length; e++) n[r[e]] = B(r[e]);
        n.name = S(t);
        var a, i = B("parentInput");
        return i && (a = C(i, n.type), a && (n.parentType = a.type, n.parentId = a.id)), n.oneString && (n.withParents = !0), n
      }

      function C(e, n) {
        var r, a = t.fias.getInputs(e), i = t.fias.type, o = {}, u = null;
        a.each(function () {
          var e, n = t(this);
          (e = n.attr("data-kladr-id")) && (o[n.attr("data-kladr-type")] = e)
        });
        for (r in i) {
          if (r == n) return u;
          l(i, r) && o[r] && (u = {type: r, id: o[r]})
        }
        return u
      }

      function S(t) {
        for (var e = "abcdefghijklmnopqrstuvwxyz", n = t.toLowerCase(), r = 0; r < n.length; r++) if (~e.indexOf(n[r])) return x(!0), t;
        return x(!1), t
      }

      function _(t, e) {
        var n = B("current");
        (n && n.id) !== (t && t.id) && (P("current", t), t && t.id ? r.attr("data-kladr-id", t.id) : r.removeAttr("data-kladr-id"), B("oneString") && t && t.contentType && r.attr("data-kladr-type", t.contentType), w("change", t, e))
      }

      function x(t) {t ? r.addClass("kladr-error") : r.removeClass("kladr-error")}

      function B(t) {return c._get(t)}

      function P(t, e) {c._set(t, e)}

      var V = null, A = null, G = !1, z = ".kladr", F = "kladrInputChange";
      a(function () {
        var n = !1, a = !0, i = "";
        r.attr("data-kladr-type", B("type") || "").attr("data-kladr-one-string", B("oneString") || null).on("keyup" + z, p).on("keydown" + z, y).on("blur" + z, function () {!n && r.data(F) && i != r.val() && r.change()}).on("blur" + z + " change" + z, function (t) {if (!n) return "change" == t.type && (i = r.val()), a && (a = !1, m()), !G && u.checkEmptyRespone && r.val(""), v(), !1}).on("focus" + z, function () {a = !0}), V.on("touchstart" + z + " mousedown" + z, "li, a", function (t) {t.preventDefault(), n = !0, h(this), n = !1}), t(e).on("resize" + z, d)
      })
    })
  }

  function i(e, n) {
    var a = {obj: !1, str: !1, isGet: !1};
    return "object" === t.type(e) ? (a.obj = e, a) : ("string" === t.type(e) && (a.str = [e, n], a.isGet = n === r), a)
  }

  function o() {return o.guid ? ++o.guid : o.guid = 1}

  function l(t, e) {return t.hasOwnProperty(e)}

  var u = {
    token: null,
    key: null,
    type: null,
    typeCode: null,
    parentType: null,
    parentId: null,
    limit: 10,
    oneString: !1,
    withParents: !1,
    noResultText: null,
    checkEmptyRespone: !1,
    strict: null,
    parentInput: null,
    verify: !1,
    spinner: !0,
    open: null,
    close: null,
    send: null,
    receive: null,
    select: null,
    check: null,
    change: null,
    openBefore: null,
    closeBefore: null,
    // sendBefore: null,
    sendBefore: function () {
      if (typeof window.loader !== 'undefined') {
        window.loader.enabled = false;
      }
    },
    selectBefore: null,
    checkBefore: null,
    source: function (e, n) {t.fias.api(e, n)},
    labelFormat: function (e, n) {
      var r, a = function (t) {
        var e = ["-", "[", "]", "/", "{", "}", "(", ")", "*", "+", "?", ".", "\\", "^", "$", "|"],
          r = RegExp("[" + e.join("\\") + "]", "g"), a = n.name.toLowerCase().replace(r, "\\$&"),
          i = new RegExp(a, "gi");
        return t.replace(i, "<strong>$&</strong>")
      };
      if (n.oneString) return e.parents ? (r = [].concat(e.parents), r.push(e), a(t.fias.buildAddress(r))) : a((e.typeShort ? e.typeShort + ". " : "") + e.name);
      var i = "";
      if (e.typeShort && (i += e.typeShort + ". "), i += e.name, i = a(i), e.parents) for (var o = e.parents.length - 1; o > -1; o--) {
        var l = e.parents[o];
        l.name && (i && (i += "<small>, </small>"), i += "<small>" + l.name + " " + l.typeShort + ".</small>")
      }
      return i
    },
    valueFormat: function (e, n) {
      var r;
      return n.oneString ? e.parents ? (r = [].concat(e.parents), r.push(e), t.fias.buildAddress(r)) : (e.typeShort ? e.typeShort + ". " : "") + e.name : e.name
    },
    showSpinner: function (t) {
      var e = -.2,
        n = setInterval(function () {return t.is(":visible") ? (t.css("background-position", "0% " + e + "%"), e += 5.555556, void (e > 95 && (e = -.2))) : (clearInterval(n), void (n = null))}, 30);
      t.show()
    },
    hideSpinner: function (t) {t.hide()}
  }, s = {current: null, controller: null}, f = {up: 38, down: 40, enter: 13};
  t.fias = t.extend(t.fias, {
    setDefault: function (t, e) {
      var n = i(t, e);
      if (n.obj) for (var r in n.obj) l(u, r) && (u[r] = n.obj[r]); else n.str && !n.isGet && l(u, n.str[0]) && (u[n.str[0]] = n.str[1])
    },
    getDefault: function (t) {if (l(u, t)) return u[t]},
    getInputs: function (e) {
      var r = t(e || n.body), a = "[data-kladr-type]";
      return r.filter(a).add(r.find(a))
    },
    setValues: function (e, n, r) {
      var a, i, o = "kladr_change.setvalues", u = t.fias.type, s = {}, f = {};
      if (~t.inArray(t.type(e), ["object", "array"])) {
        t.each(e, function (t, e) {
          if (e) {
            var n = e.contentType || e.type || t;
            l(u, n) && (s[n] = e)
          }
        });
        for (i in u) l(u, i) && s[i] && (f[i] = s[i]);
        a = t.fias.getInputs(n), function c() {
          var t, e, n;
          for (e in f) if (l(f, e)) {
            n = f[e], delete f[e];
            break
          }
          if (e) return t = a.filter('[data-kladr-type="' + e + '"]'), t.length ? void t.on(o, function () {t.off(o), c()}).fias("controller").setValue(n, r) : void c()
        }()
      }
    },
    getAddress: function (e, n) {
      var r, a = t.fias.getInputs(e), i = t.fias.type, o = {}, u = {};
      a.each(function () {
        var e, n, r, a = t(this);
        if (a.attr("data-kladr-id")) if (e = a.fias("current"), a.attr("data-kladr-one-string") && e.parents) for (n = [].concat(e.parents), n.push(e), r = 0; r < n.length; r++) o[n[r].contentType] = n[r]; else o[a.attr("data-kladr-type")] = e; else o[a.attr("data-kladr-type")] = a.val()
      });
      for (r in i) l(i, r) && o[r] && (u[r] = o[r]);
      return (n || t.fias.buildAddress)(u)
    },
    buildAddress: function (e) {
      var n = [], r = "", a = "";
      return t.each(e, function (e, i) {
        var o, l = "", u = "";
        if ("object" === t.type(i)) {
          for (o = 0; o < n.length; o++) if (n[o] == i.id) return;
          n.push(i.id), l = i.name, u = i.typeShort + ". ", a = i.zip || a
        } else l = i;
        r && (r += ", "), r += u + l
      }), r = (a ? a + ", " : "") + r
    }
  }), t.fn.fias = function (e, n) {
    var r = i(e, n), o = null;
    return this.each(function () {
      var e = a(t(this), r);
      if (r.isGet) return o = e, !1
    }), r.isGet ? o : this
  }
}(jQuery, window, document), function (t) {
  $.fn.fiasZip = function (selector, found) {
    this.keydown(function (e) {
      var key = e.charCode || e.keyCode || 0,
        allow = (
          key == 8 ||
          key == 9 ||
          key == 13 ||
          key == 46 ||
          key == 110 ||
          key == 190 ||
          (key >= 35 && key <= 40) ||
          (key >= 96 && key <= 105)
        );

      if ($(this).val().length >= 6) {
        return allow;
      }

      return (allow || (key >= 48 && key <= 57));
    });

    this.keyup(function () {
      var $this = $(this),
        zip = $this.val();

      if (!zip) {
        error(false);
        return;
      }

      $.fias.api({
        type: $.fias.type.building,
        zip: zip,
        withParents: true,
        sendBefore: function () {
          if (typeof window.loader !== 'undefined') {
            window.loader.enabled = false;
          }
        },
        limit: 1
      }, function (objs) {
        var obj = objs.length && objs[0];
        objs = [];

        if (obj) {
          error(false);

          if (obj.parents) {
            objs = objs.concat(obj.parents);
          }
          objs.push(obj);

          // fix:
          let nobjs = [];
          for (let i = 0, len = objs.length; i < len; i++) {
            if (['street', 'building'].indexOf(objs[i].contentType) < 0) {
              nobjs.push(objs[i]);
            }
          }
          objs = nobjs;
          //

          $.fias.setValues(objs, selector);
          found && found(obj);
        }
        else {
          error(true);
        }
      });

      function error(er) {
        er ? $this.addClass('kladr-error') : $this.removeClass('kladr-error');
      }
    });

    return this;
  };
}(jQuery);
