$(document).ready(function () {
  let $modal_qr = $('.js-modal--check-qr'), $qr_container = $modal_qr.find('.modal__qr');
  if ($modal_qr.length < 1) return;

  let video = document.getElementById('preview'),
    canvasElement = document.createElement('canvas'),
    canvas = canvasElement.getContext('2d');

  let cannotScanQR = (typeof navigator.mediaDevices === 'undefined');
  if (cannotScanQR) {
    $('[data-modal="js-modal--check-reg"]').each(function () {
      $(this).attr('data-modal', 'js-modal--check-data').data('modal', 'js-modal--check-data');
    });
    $('.form__upload_check_data .form__row:gt(2)').hide();
  }

  // jsQR
  (function () {
    $('[data-modal="js-modal--check-qr"]').on('click', function () {
      if (cannotScanQR) {
        // окно ввода данных чека вручную
        jewelryModals.show('js-modal--check-data');
        return false;
      } else {
        startVideo();
      }
    });

    function startVideo() {
      if (video.srcObject === null) {
        navigator.mediaDevices.getUserMedia({video: {facingMode: "environment", frameRate: {ideal: 5, max: 10}}})
          .then(function (stream) {
            video.srcObject = stream;
            video.setAttribute('autoplay', true);
            video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
            video.play();
          });
      }
    }

    function stopVideo() {
      video.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
      video.srcObject = null;
    }

    raf(function checkVideoIsVisible() {
      if ($modal_qr.is(':visible')) {
        if (video.srcObject !== null) {
          try {
            canvasElement.height = video.videoHeight;
            canvasElement.width = video.videoWidth;
            canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
            let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height),
              code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: "dontInvert",
              });

            if (code) {
              stopVideo();

              // console.log(code.data);
              $('#check_qr_qr').val(code.data);

              $qr_container.addClass('modal__qr--scanned');
              $qr_container.find('.qr__container').empty().qrcode(code.data);

              // сразу отправляем в CRM
              $('#check_qr_qr').closest('form').trigger('submit');
            }
          } catch (e) {
            //console.log('PROBLEM: ', e);
          }
        }
      } else {
        $qr_container.removeClass('modal__qr--scanned');
        $('#check_qr_qr').val('');
        if (video.srcObject !== null) {
          stopVideo();
        }
      }
      raf(checkVideoIsVisible);
    });
  })();

});
