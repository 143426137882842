$(document).ready(function () {

  // video
  // (function () {
  //   let aud = document.getElementById("video");
  //   if (aud !== null) {
  //     aud.onended = function () {
  //       //$('.landing__screen--1').addClass('landing__screen--video-end');
  //       //$('#video').fadeOut(1000);
  //     };
  //   }
  // })()

  var step = 1;
  $('.js--main-next').on('click', function () {
    step++;
    if (step > 6) {
      step = 1;
    }
    $('.main__slides').removeClass('main__slides--1').removeClass('main__slides--2').removeClass('main__slides--3').removeClass('main__slides--4').removeClass('main__slides--5').removeClass('main__slides--6').addClass('main__slides--' + step);
    //console.log(step);
    return false;
  });

  // quizplease
  (function () {
    let vid = document.getElementById('video')
    if (vid !== null) {
      let vidInterval = null,
        checkVideoIsEnded = function () {
          if (vid.currentTime > 1.4) {
            window.clearInterval(vidInterval);
            $('body').addClass('video_1sec');
          }
        };
      vidInterval = window.setInterval(checkVideoIsEnded, 100); // проверяем каждый N-секунд
    }
  })();


  if (location.pathname === "/") {
     jewelryModals.show('modal--end');
  }

  // video {
  if ($('#video-screen-3').length) {
    // $('#video-screen-3').bind('ended', function () {
    //   this.currentTime = 0;
    // });
    var video = $('#video-screen-3').get(0);
    video.addEventListener('ended', function () {
      video.load();
    }, false);
  }
  $('.quiz-start__video video').on('play', function (e) {
    dataLayer.push({'event': 'start_video',});
  });

  // quizplease
  (function () {
    let vid = document.getElementById('quizplease_video'), $vcode = $('.js--code_from_video');
    if ((vid !== null) && ($vcode.length)) {
      let vidInterval = null,
        checkVideoIsEnded = function () {
          if (vid.currentTime > (vid.duration - 2)) {
            window.clearInterval(vidInterval);

            $vcode.val('VCODE');
            $vcode.closest('form').trigger('submit');
            setTimeout(function () {$vcode.val('');}, 500);
            dataLayer.push({'event': 'end_video',});
          }
        };
      vidInterval = window.setInterval(checkVideoIsEnded, 3 * 1000); // проверяем каждый N-секунд
    }
  })();

  // scroll
  $('.js--scroll').on('click', function () {
    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top
    }, 400);
    return false
  });

  // меню
  $('body')
    .on('click', '.burger', function (e) {
      e.preventDefault();

      $(this).toggleClass('burger--open');
      $('body').toggleClass('body-modal');
      $('.menu').toggleClass('menu--visible');
    })
    .on('click', '.js-game-popover-next', function (e) {
      e.preventDefault();

      $(this).addClass('hidden');
      $('.game__popover--1').addClass('hidden');

      $('.game__popover--2').addClass('visible');
      $('.game__button--play').addClass('visible');

      $('.game--cacao.game--2').addClass('blue');
    })

  // игры peppers
  if ($('.js--pepper_game__form').length) {
    window.addEventListener('message', function (data) {
      if (typeof data.data !== 'undefined' && data.data.length && (data.data.indexOf('gameID') > -1)) {
        // отправляем data.data на сервер
        let $form = $('.js--pepper_game__form');
        $form.find('#pepper_game_answers').val(data.data);
        $form.trigger('submit');
      }
    });

    $('.js--pepper__play_one_more').on('click', function (e) {
      e.preventDefault();
      let page = $('.page__content').get(0);
      page.className = page.className.replace(/\sgame--\d+/, '');

      document.getElementById('doodle').contentWindow.postMessage('showIntro', '*');
    });

    $('.js--jrfox__play_one_more').on('click', function (e) {
      e.preventDefault();
      $('.game__screen.active').removeClass('active');
      $('#game').get(0).contentWindow.window.postMessage('"showIntro"', '*');
    });


    $('.js--pepper__open_share').on('click', function (e) {
      e.preventDefault();

      let page = $('.page__content').get(0);
      page.className = page.className.replace(/\sgame--\d+/, '') + ' game--14';
    });
  }

  $('.js-modal--check-data .form__input input').on('focus', function () {
    $('.form__control').removeClass('active');
    $(this).closest('.form__control').addClass('active');

    let currentCheck = $(this).attr('data-check');
    $('.form__check').attr('data-check', currentCheck);
  });

  // promo2021
  (function () {
    $('.promo3__item[data-prize-id] .promo3__item-button').on('click', function (e) {
      e.preventDefault();
      let available = parseInt($('.promo3__left .promo3__balls').html(), 10),
        prizeName = $(this).prev().data('prize'),
        points = parseInt($(this).closest('.promo3__item').find('.promo3__balls').html(), 10),
        prizeId = $(this).closest('.promo3__item').data('prize-id');

      if (isNaN(available) || isNaN(points) || available < points) {
        // нет баллов
        return false;
      }

      let modal = 'js-modal--promo3-prize', $modal = $('.' + modal), $select_prize = $modal.find('.js--select_prize');
      $modal.find('.modal__top .modal__text').html(prizeName);

      $select_prize.off('click');
      if (points > 10) {
        $select_prize.on('click', function (e) {
          e.preventDefault();

          let $form = $('.js-modal--promo3-address form');
          $form.find('input[type=hidden]').val(prizeId);

          jewelryModals.show('js-modal--promo3-address');
        });
      } else {
        $select_prize.on('click', function (e) {
          e.preventDefault();
          let $form = $modal.find('form');
          $form.find('input[type=hidden]').val(prizeId);
          $form.trigger('submit');
        });
      }

      jewelryModals.show(modal);
    });

    // KLADR
    let $container = $('.js--prize_with_address'),
      $zip = $container.find('[name*="zip"]'),
      $region = $container.find('[name*="region"]'),
      $district = $container.find('[name*="district"]'),
      $city = $container.find('[name*="city"]'),
      $street = $container.find('[name*="street"]'),
      $building = $container.find('[name*="building"]');

    $()
      .add($region)
      .add($district)
      .add($city)
      .add($street)
      .add($building)
      .fias({
        parentInput: $container,
        verify: false,
      });

    $region.fias('type', $.fias.type.region);
    $district.fias('type', $.fias.type.district);
    $city.fias('type', $.fias.type.city);
    $street.fias('type', $.fias.type.street);
    $building.fias('type', $.fias.type.building);

    $district.fias('withParents', true);
    $city.fias('withParents', true);
    $street.fias('withParents', true);

    // Подключаем плагин для почтового индекса
    $zip.fiasZip($container, function (obj) {
      $region.removeAttr('disabled');
      $district.removeAttr('disabled');
      $city.removeAttr('disabled');
      $street.removeAttr('disabled');
      $building.removeAttr('disabled');
    });
  })();

  // share v21
  (function () {
    $(document).on('click', '.game__socials .game__social', function (e) {
      let $parent = $(this).parent(), $form = $parent.find('.js--share_game__form');
      if (($form.length < 1) && $parent.data('form-id')) {
        $form = $($parent.data('form-id'));
      }

      if ($form.length > 0) {
        $form.trigger('submit');
      }
    });
  })();
});
